// extracted by mini-css-extract-plugin
export var bottomPanel = "tab-module--bottom-panel--62f9e";
export var bottomPanelLink = "tab-module--bottom-panel-link--5af01";
export var customCardCategories = "tab-module--custom-card-categories--784e5";
export var customCardContainer = "tab-module--custom-card-container--f069e";
export var customCardDescription = "tab-module--custom-card-description--a7c4b";
export var customCardImage = "tab-module--custom-card-image--90563";
export var customCardInfo = "tab-module--custom-card-info--b94fe";
export var customCardTitle = "tab-module--custom-card-title--085b1";
export var tabContent = "tab-module--tab-content--0d3be";
export var tabContentActive = "tab-module--tab-content-active--7a9e6";
export var tabContentFeatured = "tab-module--tab-content-featured--b3541";
export var tabContentOther = "tab-module--tab-content-other--61d9f";