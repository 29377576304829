import React from 'react';
import useSiteMetadata from '@hooks/useSiteMetadata';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { getCanonicalURL } from '@src/utils/getCanonicalURL';
import { defaultData } from './payload';
import { BlogListLayoutContent } from '@pages-impl/blog/blog-list-layout-content/BlogListLayoutContent';

export default function BlogListLayout(props) {
  const { location, data, pageContext } = props;
  const { seoTitle, seoDescription, featuredImage } = data.details?.frontmatter ?? {};
  const { defaultSeoTitle, defaultSeoDescription, defaultFeaturedImage } = defaultData;
  const { siteURL } = useSiteMetadata();

  const { currentPage } = pageContext;
  const title = seoTitle || defaultSeoTitle;

  const seoTitleWithPagination = currentPage === 1 ? title : `${title} | Page ${currentPage}`;

  return (
    <Layout location={location}>
      <SEO
        title={seoTitleWithPagination}
        description={seoDescription || defaultSeoDescription}
        featuredImage={featuredImage?.publicURL || defaultFeaturedImage}
        canonicalPageUrl={getCanonicalURL(siteURL, location.pathname)}
      />
      <BlogListLayoutContent data={data} pageContext={pageContext} location={location} />
    </Layout>
  );
}
