import React from 'react';
import { graphql } from 'gatsby';
import BlogListLayout from '../BlogListLayout';

const BlogListCategoryViewTemplate = (props) => {
  return <BlogListLayout {...props} />;
};

export default BlogListCategoryViewTemplate;

export const blogPostsByCategoryQuery = graphql`
  query BlogPostsByCategory($postCategoryName: String!, $limit: Int, $skip: Int) {
    blogPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          layout: { eq: "blog" }
          postCategories: { elemMatch: { frontmatter: { postCategoryName: { eq: $postCategoryName } } } }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        ...BlogPostNode
      }
    }
    details: markdownRemark(frontmatter: { postCategoryName: { eq: $postCategoryName } }) {
      frontmatter {
        postBanner {
          frontmatter {
            postBannerTitle
            postBannerSubtitle
            postBannerLabel
            postBannerButtonLabel
            postBannerButtonUrl
          }
        }
        description
        seoTitle
        seoDescription
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
    }
  }
`;
